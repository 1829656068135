import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Spin } from 'antd';

const NavListCustomInfinite = ({
  makeActive,
  data,
  active,
  fetchMoreData,
  hasMore,
  loading,
  isFetching,
}) => {
  const [activeItem, setActiveItem] = useState(active);
  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchMoreData();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (active) {
      setActiveItem(active);
    }
  }, [active]);

  const _makeActive = useCallback((id) => {
    setActiveItem(id);
    makeActive(id);
  }, []);

  const items = (data || []).map((item, i) => {
    if (data.length === i + 1) {
      return (
        <span
          ref={lastItemRef}
          key={item.id}
          onClick={() => _makeActive(item.id)}
          className={classNames(
            'inline-block list-none w-full min-h-[30px] align-middle hover:bg-color2 hover:text-white cursor-pointer leading-none text-sm text-gray-500 py-[5px] px-2 border-b border-neutral-200',
            {
              'bg-color2 text-white': item.id === activeItem,
            }
          )}
        >
          {item.title}
        </span>
      );
    }
    return (
      <span
        key={item.id}
        onClick={() => _makeActive(item.id)}
        className={classNames(
          'inline-block list-none w-full min-h-[30px] align-middle hover:bg-color2 hover:text-white cursor-pointer leading-none text-sm text-gray-500 py-[5px] px-2 border-b border-neutral-200',
          {
            'bg-color2 text-white': item.id === activeItem,
          }
        )}
      >
        {item.title}
      </span>
    );
  });

  if (isFetching) {
    items.push(
      <div className="flex justify-center py-3">
        <Spin />
      </div>
    );
  }

  return items;
};

export default NavListCustomInfinite;
