/* eslint-disable no-unused-vars */
import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Search from 'antd/lib/input/Search';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import Filter from '../../components/filter/Filter';
import { SearchContext } from '../../components/project/building/Building';
import BaseContainer from '../../containers/BaseContainer';
import Bar from '../../elements/Bar';
import NavListCustomInfinite from '../../elements/NavListCustomInfinite';
import { useGetNavigation } from '../../hookd/useNavigation';
import useWindowSize from '../hooks/windowSizeHook';

export default function NLeftPanel({
  makeActive,
  active,
  addItem,
  title,
  filteredValues,
  disableAdd = false,
  classification,
  permission = 2,
  queryKey,
}) {
  const [windowWidth, windowHeight] = useWindowSize();
  const [visibleFilter, setVisibleFilter] = useState(false);

  const { search, filter, setSearch } = useContext(SearchContext) || {};

  const queryClient = useQueryClient();
  const { fetchNextPage, hasNextPage, isFetching, isLoading, navigationItems } = useGetNavigation(
    queryKey,
    search,
    filter,
    classification
  );

  useEffect(() => {
    if (!active && navigationItems.length > 0 && !isLoading && !isFetching) {
      makeActive(navigationItems[0]?.id);
    }
  }, [active, navigationItems, isLoading, isFetching, hasNextPage]);

  const handleDebouncedSearch = debounce((e) => {
    setSearch(e.target.value);
  }, 400);

  const prepareData = () => (navigationItems || []).map((item) => ({ ...item, text: item?.title }));

  const handleFilter = (values) => {
    setVisibleFilter(false);
  };

  const content = () => {
    const items = [
      <Filter
        key={1}
        filteredValues={filteredValues}
        visible={visibleFilter}
        value={filter}
        onFilter={handleFilter}
        onClose={() => setVisibleFilter(false)}
      >
        <FilterOutlined onClick={() => setVisibleFilter(true)} />
      </Filter>,
    ];
    if (permission === 2 && !disableAdd) {
      items.push(<PlusOutlined key={2} onClick={addItem} />);
    }

    return items;
  };

  return (
    <div>
      <BaseContainer width="420px" margin className="border-r">
        <Bar text={title} content={content()} />
        <Search
          style={{ height: '30px' }}
          placeholder="Vyhledat"
          onChange={handleDebouncedSearch}
        />
        <div style={{ height: 'calc(100vh - 163px)' }} className="overflow-auto">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Spin />
            </div>
          ) : (
            <NavListCustomInfinite
              height={(windowHeight || 500) - 170}
              data={prepareData()}
              makeActive={makeActive}
              active={active}
              hasMore={hasNextPage}
              dataLength={null}
              fetchMoreData={fetchNextPage}
              loading={isLoading}
              isFetching={isFetching}
            />
          )}
        </div>
      </BaseContainer>
    </div>
  );
}
