import { useEffect, useState, React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainContainer from '../../../containers/MainContainer';
import TypeMarkDetailView from './components/TypeMarkDetailView';
import {
  CLEAR_TYPE_MARKS,
  fetchDetail,
  fetchGeometry,
  fetchInstances,
  GET_TYPE_MARK_DETAIL,
} from '../../../store/actions/typemark';
import { useQueryClient } from 'react-query';
import NLeftPanel from '../../../shared/components/NLeftPanel';
import { SearchContext } from '../building/Building';

function TypeMark({ match, isRevitView }) {
  const [activeItemId, setActiveItemId] = useState(null);
  const { pagePermission } = useSelector((state) => state.auth);
  const { contractId } = useSelector((state) => state.contract);
  const [search, setSearch] = useState(null);
  const [filter, setFilter] = useState({ is_active: [true] });
  const queryClient = useQueryClient();
  const queryKey = 'instance-type';

  const { detail, geometry, menuId } = useSelector((state) => state.typeMark);

  const dispatch = useDispatch();

  useEffect(() => {
    if (menuId && contractId) {
      queryClient.invalidateQueries(queryKey);
      dispatch({ type: CLEAR_TYPE_MARKS });
    }
  }, [menuId, contractId]);

  useEffect(() => {
    if (match?.params?.id) {
      getDetail(match.params.id);
    }
  }, [match?.params?.id]);

  const getDetail = (id) => {
    dispatch({ type: GET_TYPE_MARK_DETAIL });
    dispatch(fetchDetail(id));
    dispatch(fetchGeometry(id));
    dispatch(fetchInstances(id, 1, 10));
    setActiveItemId(id);
  };

  const onSearch = async (e) => {
    setSearch(e?.target?.value);
  };

  if (isRevitView) {
    return (
      <TypeMarkDetailView
        data={detail}
        activeItemId={activeItemId}
        geometry={geometry}
        permission={pagePermission}
        // makeEditable={editDetail}
        isRevitView
      />
    );
  }

  return (
    <SearchContext.Provider
      value={{
        search,
        filter,
        setSearch,
        setFilter,
      }}
    >
      <MainContainer>
        <NLeftPanel
          queryKey={queryKey}
          makeActive={getDetail}
          active={detail?.id}
          onSearch={onSearch}
          addItem={() => {}}
          title="Typemarky"
          disableAdd
          permission={pagePermission}
          filteredValues={{
            is_active: [
              { value: true, title: 'Aktivní' },
              { value: false, title: 'Neaktivní' },
            ],
          }}
        />

        {activeItemId && (
          <TypeMarkDetailView activeItemId={activeItemId} permission={pagePermission} />
        )}
      </MainContainer>
    </SearchContext.Provider>
  );
}

export default TypeMark;
