// eslint-disable-next-line import/no-extraneous-dependencies
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import React from 'react';
import { Delete16Filled, Delete24Filled, Edit16Filled } from '@fluentui/react-icons';

function DataTable(props) {
  const permission = useSelector((state) => state.auth.pagePermission);

  const columns = () =>
    props.columns.map((column, i) => {
      if (column.key === 'id') {
        return (
          <th
            className="py-2 w-[50px] text-sm text-left pl-2 border-r last:border-r-0"
            key={column.key}
          />
        );
      }
      return (
        <th
          className="py-2 uppercase text-color2 tracking-widest text-[12px] text-left pl-2 border-r last:border-r-0"
          key={column.key}
        >
          {column.title}
        </th>
      );
    });

  const rows = (data) => {
    if (!data) return [];
    return data.map((item, i) => (
      <tr
        className="text-color2 even:bg-neutral-100 even:bg-opacity-60 border-b last:border-b-0"
        key={item.id}
      >
        {props.columns.map((column) => {
          if (column.key === 'title') {
            return (
              <td className="p-2 text-[13px] border-r" colSpan={item.colSpan} key={column.key}>
                {item[column.key]}
              </td>
            );
          }
          if (column.key === 'id') {
            return (
              <td
                key={column.key}
                className="flex h-full gap-2 p-2 justify-center border-r items-center"
              >
                {props.onEdit && permission === 2 && (
                  <Tooltip placement="left" title="Editovat položku">
                    <Edit16Filled onClick={() => props.onEdit(item.id)} />
                  </Tooltip>
                )}
                {props.onDelete && permission === 2 && (
                  <Popconfirm
                    placement="topLeft"
                    title="Opravdu chcete položku odstranit?"
                    onConfirm={() => props.onDelete(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip placement="left" title="Odstranit položku">
                      <Delete16Filled className="cursor-pointer" />
                    </Tooltip>
                  </Popconfirm>
                )}
              </td>
            );
          }
          return (
            <td className="p-2 text-[13px] border-r" key={column.key}>
              {item[column.key]}
            </td>
          );
        })}
      </tr>
    ));
  };

  return (
    <table className="table-fixed w-full">
      <thead className="border-b border-neutral-300">
        <tr>{columns()}</tr>
      </thead>
      <tbody>{rows(props.data)}</tbody>
    </table>
  );
}

export default DataTable;
