import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Field, Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import Bar from '../../../elements/Bar';
import FormList from '../../../elements/FormList';
import { InputField } from '../../../shared/InputField';
import { SelectField } from '../../../shared/SelectField';
import Modal from '../../../shared/modal/Modal';
import ModalButton from '../../../shared/modal/ModalButton';
import PickClassificationModal from '../../../shared/modals/PickClassificationModal';
import PickValueWithClassificationModal from '../../../shared/modals/PickValueWithClassificationModal';
import Label from '../../Label';
import { useGetBuildingObjects, useGetTemplates } from '../hooks/getDropdownOptions';
import useGetFilter from '../hooks/getFilter';
import { apiService } from '../../../utills/api';

const PrintSettingsModal = ({ filterId, onSubmit, visible, onClose, document }) => {
  const formRef = useRef();
  const { data } = useGetFilter(filterId);
  const { data: buildingObjects } = useGetBuildingObjects();
  const { data: templates } = useGetTemplates();

  const [visibleAddTechnicalReportItem, setVisibleAddTechnicalReportItem] = useState(false);
  const [visibleClassificationModal, setVisibleClassificationModal] = useState(false);
  const [visibleAddTechnicalReportItemSetDialog, setVisibleAddTechnicalReportItemSetDialog] =
    useState(false);

  const handleAddTechnicalReportItem = (item) => {
    let uniq = [...formRef.current.values.classified_technical_report_item_set, item].filter(
      (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
    );

    formRef.current.setFieldValue('classified_technical_report_item_set', uniq);

    setVisibleAddTechnicalReportItem(false);
  };

  const handleDeleteTechnicalReportItem = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.classified_technical_report_item_set.filter(
      (item) => item.id !== id
    );

    formRef.current.setFieldValue('classified_technical_report_item_set', newValues);
  };

  const handleAddClassificationItem = (item) => {
    formRef.current.setFieldValue('classification', [
      ...formRef.current.values.classification,
      item,
    ]);
    setVisibleClassificationModal(false);
  };

  const handleDeleteClassificationItem = (id) => {
    if (!formRef?.current) return null;

    const newValues = formRef.current.values.classification.filter((item) => item.id !== id);

    formRef.current.setFieldValue('classification', newValues);
  };

  const handleAddTechnicalReportItemSet = async (item) => {
    try {
      const { data } = await apiService.get(
        `/api/v1/set-technical-report-item/${item.id_detail}/last-version/`
      );

      let uniq = [
        ...formRef.current.values.classified_technical_report_item_set,
        ...data.technical_report_item_set,
      ].filter((obj, index, self) => index === self.findIndex((o) => o.id === obj.id));

      formRef.current.setFieldValue('classified_technical_report_item_set', uniq);

      setVisibleAddTechnicalReportItemSetDialog(false);
    } catch (e) {
      //
    }
  };

  return (
    <Modal
      title={filterId ? 'Editace nastavení tisku' : 'Nastavení tisku'}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton type="submit" key={2} primary action={() => formRef.current?.submitForm()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <div>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            title: data?.title || document.title || null,
            common_note: data?.common_note || null,
            concept_description: data?.concept_description || null,
            classified_technical_report_item_set: data?.classified_technical_report_item_set || [],
            classification: data?.classification || [],
            template: data?.template || null,
            building_object: data?.building_object || null,
          }}
          onSubmit={(values) => {
            const classification = values.classification.map((item) => item.id);
            const reportItemSet = values.classified_technical_report_item_set.map(
              (item) => item.id
            );
            onSubmit(filterId, {
              ...values,
              classification,
              classified_technical_report_item_set: reportItemSet,
            });
          }}
        >
          {(props) => {
            const { values, setFieldValue } = props;
            return (
              <Form>
                <div>
                  <Label name="title" title="Název filtru" required />

                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder=""
                    component={InputField}
                  />
                </div>
                <div>
                  <Label name="template" title="Šablona" required />

                  <Field
                    name="template"
                    value={values.template || null}
                    placeholder=""
                    component={SelectField}
                    data={templates ?? []}
                    onChange={(val) => {
                      setFieldValue('template', val);
                    }}
                  />
                </div>

                <div className="mb-4 mt-2">
                  <Bar
                    text="Kategorie"
                    content={[
                      <PlusOutlined key={1} onClick={() => setVisibleClassificationModal(true)} />,
                    ]}
                  />
                  <FormList
                    data={values.classification}
                    onDelete={handleDeleteClassificationItem}
                  />
                </div>

                <div>
                  <Label name="building_object" title="Stavební objekt" required />

                  <Field
                    name="building_object"
                    value={values.building_object || null}
                    placeholder=""
                    component={SelectField}
                    data={buildingObjects ?? []}
                    onChange={(val) => {
                      setFieldValue('building_object', val);
                    }}
                  />
                </div>

                <div>
                  <Label name="concept_description" title="Popis koncepce" />
                  <CKEditor
                    data={values.concept_description}
                    editor={ClassicEditor}
                    config={
                      {
                        // list: {
                        //   listType: 'numbered',
                        // },
                      }
                    }
                    onChange={(e, editor) => {
                      const data = editor.getData();
                      setFieldValue('concept_description', data);
                    }}
                  />
                </div>

                <div>
                  <Label name="common_note" title="Společná poznámka" />
                  <CKEditor
                    data={values.common_note}
                    editor={ClassicEditor}
                    config={{}}
                    onChange={(e, editor) => {
                      const data = editor.getData();
                      setFieldValue('common_note', data);
                    }}
                  />
                </div>

                <div className="mb-4 mt-2">
                  <Bar
                    text="Prvky technické zprávy"
                    content={[
                      <PlusOutlined
                        key={1}
                        onClick={() => setVisibleAddTechnicalReportItem(true)}
                      />,
                      <UnorderedListOutlined
                        key={1}
                        onClick={() => setVisibleAddTechnicalReportItemSetDialog(true)}
                      />,
                    ]}
                  />
                  <FormList
                    data={values.classified_technical_report_item_set}
                    onDelete={handleDeleteTechnicalReportItem}
                  />
                </div>
                <PickValueWithClassificationModal
                  visible={visibleAddTechnicalReportItem}
                  url="left-panel-technical-report-item/"
                  pickerUrl="technical-report-item/left-panel-picker/"
                  onClose={() => setVisibleAddTechnicalReportItem(false)}
                  onSubmit={handleAddTechnicalReportItem}
                />
                <PickClassificationModal
                  visible={visibleClassificationModal}
                  url="left-panel-technical-report-item/"
                  onClose={() => setVisibleClassificationModal(false)}
                  onSubmit={handleAddClassificationItem}
                />
                <PickValueWithClassificationModal
                  visible={visibleAddTechnicalReportItemSetDialog}
                  url="left-panel-set-technical-report-item/"
                  pickerUrl="set-technical-report-item/left-panel-picker/"
                  onClose={() => setVisibleAddTechnicalReportItemSetDialog(false)}
                  onSubmit={handleAddTechnicalReportItemSet}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default PrintSettingsModal;
