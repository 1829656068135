import { EditOutlined, PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import React, { Suspense, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Spin } from 'antd';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';
import DataTable from '../../../../shared/components/DataTable';
import PickValueWithClassificationModal from '../../../../shared/modals/PickValueWithClassificationModal';
import {
  addProperty,
  addTechnicalReportItem,
  removeProperty,
  removeTechnicalReportItem,
  updateTypeMarkBasicData,
  addSolution,
  removeSolution,
  addPropertySet,
  addTechnicalReportItemSet,
  addColor,
  removeColor,
  applyTemplate,
} from '../../../../store/actions/typemark';
import TypeMarkModal from './modals/TypeMarkModal';
import { isEmptyArray } from '../../../../utills/array';
import TypeMarkComponentTable from './TypeMarkComponentTable';
import ColorsModal from './modals/ColorsModal';
import SolutionPropertyDefinitionModal from '../../../database/solution/components/modals/SolutionPropertyDefinitionModal';
import { findProperty } from '../../../../utills/typemark';
import InstanceTable from './InstanceTable';
import View from '../../../geometry/view';
import UpdateGeometryModal from './modals/UpdateGeometryModal';
import SolutionPickerModal from '../../../../shared/modals/SolutionPickerModal';
import { useQueryClient } from 'react-query';
import { List16Regular } from '@fluentui/react-icons';
import TemplateModal from './modals/TemplateModal';

const detailData = ({ title, type_mark, description } = {}) => [
  { name: 'Označení', value: type_mark },
  { name: 'Název', value: title },
  { name: 'Popis', value: description },
];

const prepareDataForComponentSet = (component_set) => {
  if (!component_set) return [];
  const result = [];
  component_set.forEach((component) => {
    const values = {
      title: component.title,
      id: component.id,
      solution: null,
      color: null,
    };
    if (component.solution_set.length < 1) {
      result.push({ ...values, rowSpan: 1 });
    }
    component.solution_set.forEach((solution, i) => {
      if (i === 0) {
        result.push({
          ...values,
          solution,
          color: solution?.appearance,
          rowSpan: component.solution_set.length,
        });
      } else {
        result.push({
          ...values,
          solution,
          color: solution?.appearance,
          rowSpan: 0,
        });
      }
    });
  });

  return result;
};

export default function TypeMarkDetailView({ activeItemId, permission, isRevitView }) {
  const [visibleAddPropertyDialog, setVisibleAddPropertyDialog] = useState(false);
  const [visibleAddPropertyValueDialog, setVisibleAddPropertyValueDialog] = useState(false);
  const [visibleAddPTechnicalReportItemDialog, setVisibleAddPTechnicalReportItemDialog] =
    useState(false);
  const [visibleBasicEditDialog, setVisibleBasicEditDialog] = useState(false);
  const [visibleAddComponent, setVisibleAddComponent] = useState(false);
  const [activeComponentId, setActiveComponentId] = useState(null);
  const [pickedSolutionId, setPickedSolutionId] = useState(null);
  const [classifiedSolutionId, setClassifiedSolutionId] = useState(null);

  const [visibleColorsDialog, setVisibleColorsDialog] = useState(false);
  const [propertyIdForSetValue, setPropertyIdForSetValue] = useState(false);
  const [showGeometryViewDialog, setShowGeometryViewDialog] = useState(false);
  const [visibleAddPropertySetDialog, setVisibleAddPropertySetDialog] = useState(false);

  const [templatesModalOpen, setTemplatesModalOpen] = useState(false);
  const [visibleAddTechnicalReportItemSetDialog, setVisibleAddTechnicalReportItemSetDialog] =
    useState(false);
  const {
    detail: data,
    loading,
    loadingGeo,
    loadingInstances,
    geometry,
  } = useSelector((state) => state.typeMark);
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const handleAddPropertySet = (value) => {
    dispatch(
      addPropertySet(activeItemId, {
        classified_property_definition_set: value.id,
      })
    );
    setVisibleAddPropertySetDialog(false);
  };

  const handleAddTechnicalReportItemSet = (value) => {
    dispatch(
      addTechnicalReportItemSet(activeItemId, {
        classified_technical_report_item_set: value.id,
      })
    );
    setVisibleAddTechnicalReportItemSetDialog(false);
  };

  const handleAddProperty = (value) => {
    dispatch(
      addProperty(activeItemId, {
        classified_property_definition: value.id,
        value: null,
      })
    );
    setVisibleAddPropertyDialog(false);
  };

  const handleRemoveProperty = (id) => {
    const selectedProperty = findProperty(id, data?.property_set);

    dispatch(
      removeProperty(activeItemId, {
        classified_property_definition: selectedProperty.property_definition.classified_id,
      })
    );
  };

  const handleAddTechnicalReportItem = (values) => {
    dispatch(
      addTechnicalReportItem(activeItemId, {
        classified_technical_report_item: values.id,
      })
    );
    setVisibleAddPTechnicalReportItemDialog(false);
  };

  const handleRemoveTechnicalReportItem = (id) => {
    dispatch(
      removeTechnicalReportItem(activeItemId, {
        classified_technical_report_item: id,
      })
    );
  };

  const handleAddPropertyValue = (selectedValue) => {
    const selectedProperty = findProperty(propertyIdForSetValue, data?.property_set);

    if (!propertyIdForSetValue) return;

    dispatch(
      addProperty(activeItemId, {
        classified_property_definition: selectedProperty.property_definition.classified_id,
        ...selectedValue,
      })
    );
    setVisibleAddPropertyValueDialog(false);
    setPropertyIdForSetValue(null);
  };

  const handleCloseTypeMarkDialog = () => {
    setVisibleBasicEditDialog(false);
  };

  const handleSubmitTypeMarkDialog = (values) => {
    dispatch(updateTypeMarkBasicData(activeItemId, values, queryClient));
    setVisibleBasicEditDialog(false);
  };

  const handleRemoveSolution = (id) => {
    dispatch(
      removeSolution(activeItemId, {
        id: id,
      })
    );
  };

  // eslint-disable-next-line no-shadow
  const handleRemoveColor = (id) => {
    dispatch(
      removeColor(activeItemId, {
        id,
      })
    );
  };

  const handleAddComponent = (pickedSolution) => {
    // console.log({ pickedSolution, activeComponentId, activeItemId });
    dispatch(addSolution(activeItemId, activeComponentId, pickedSolution));
    setVisibleAddComponent(false);
  };

  const handleSubmitColorsDialog = (values) => {
    dispatch(addColor(activeItemId, pickedSolutionId, classifiedSolutionId, values.appearance));
    setVisibleColorsDialog(false);
  };

  const handlePickTemplate = (template) => {
    dispatch(applyTemplate(activeItemId, template.id));
    setTemplatesModalOpen(false);
  };

  const changePosition = (position, cam, layer, zoom) => {
    // this.props.onPatchBuildItem({
    //   position: position,
    //   perspective: cam,
    //   layers: JSON.stringify(layer.map(ele => ele.layer)),
    //   zoom: zoom
    // });
    // this.setState({
    //   visible: false
    // });
  };

  // eslint-disable-next-line no-constant-condition
  if (loading) {
    return (
      <BaseContainer fullHeight width={isRevitView ? '100%' : 'calc(100% - 420px)'} margin>
        <div className="w-full h-full flex justify-center items-center">
          <Spin />
        </div>
      </BaseContainer>
    );
  }

  return (
    <div>
      <BaseContainer fullHeight width={isRevitView ? '100%' : 'calc(100% - 420px)'} margin>
        <Bar
          text={data?.title}
          content={[<List16Regular key={1} onClick={() => setTemplatesModalOpen(true)} />]}
        />
        <div className="flex flex-row justify-start w-full flex-wrap">
          <div className="mb-[1px]">
            <Bar
              text="Geometrie"
              content={
                permission === 2 && data
                  ? [<EditOutlined key={1} onClick={setShowGeometryViewDialog} />]
                  : []
              }
            />
            <div className="bg-neutral-300">
              {loadingGeo ? (
                <div className="w-[340px] h-[340px] bg-color4 flex justify-center items-center">
                  <Spin />
                </div>
              ) : geometry?.geo ? (
                <View
                  geometry={geometry.geo}
                  canvasId="three"
                  cameraType={geometry.perspective ? 'perspective' : 'orthogonal'}
                  position={geometry.position}
                  zoom={geometry.zoom}
                />
              ) : (
                <div className="w-[340px] h-[340px] bg-color4 flex justify-center items-center uppercase text-sm">
                  <p>Náhled není k dispozici</p>
                </div>
              )}
            </div>
          </div>

          <div className="flex-1">
            <Bar
              text="Detail"
              content={
                permission === 2 && data
                  ? [<EditOutlined key={1} onClick={() => setVisibleBasicEditDialog(true)} />]
                  : []
              }
            />
            <Suspense fallback={<Skeleton active />}>
              <List data={detailData(data || {})} />
            </Suspense>
          </div>
        </div>

        <div className="flex flex-col gap-[1px]">
          <div>
            <Bar text="Komponenty" content={[]} />
            {data && isEmptyArray(data.component_set) && (
              <TypeMarkComponentTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Řešení', key: 'solution' },
                  { title: 'Vzhled', key: 'color' },
                  { title: 'id', key: 'id' },
                ]}
                data={prepareDataForComponentSet(data?.component_set)}
                onDeleteSolution={handleRemoveSolution}
                onEditColor={(componentId, solutionId, classifiedSolutionId) => {
                  setActiveComponentId(componentId);
                  setPickedSolutionId(solutionId);
                  setClassifiedSolutionId(classifiedSolutionId);
                  setVisibleColorsDialog(true);
                }}
                onDeleteColor={handleRemoveColor}
                onAdd={(id) => {
                  setVisibleAddComponent(true);
                  setActiveComponentId(id);
                }}
                onDelete={() => {}}
              />
            )}
          </div>

          <div>
            <Bar
              text="Vlastnosti"
              content={
                permission === 2 && data
                  ? [
                      <PlusOutlined key={0} onClick={() => setVisibleAddPropertyDialog(true)} />,
                      <UnorderedListOutlined
                        key={1}
                        onClick={() => setVisibleAddPropertySetDialog(true)}
                      />,
                    ]
                  : []
              }
            />
            {data && isEmptyArray(data.property_set) && (
              <DataTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Hodnota', key: 'value' },
                  { title: 'Popis', key: 'description' },
                  { title: 'id', key: 'id' },
                ]}
                data={data?.property_set.map((item) => ({
                  ...item.property_definition,
                  value: item.value,
                }))}
                onDelete={handleRemoveProperty}
                onEdit={(id) => {
                  setPropertyIdForSetValue(id);
                  setVisibleAddPropertyValueDialog(true);
                }}
              />
            )}
          </div>

          <div>
            <Bar
              text="Položky technické zprávy"
              content={
                permission === 2 && data
                  ? [
                      <PlusOutlined
                        key={0}
                        onClick={() => setVisibleAddPTechnicalReportItemDialog(true)}
                      />,
                      <UnorderedListOutlined
                        key={1}
                        onClick={() => setVisibleAddTechnicalReportItemSetDialog(true)}
                      />,
                    ]
                  : []
              }
            />
            {data && isEmptyArray(data.technical_report_item_set) && (
              <DataTable
                columns={[
                  { title: 'Název', key: 'title' },
                  { title: 'Popis', key: 'description' },
                  { title: 'id', key: 'id' },
                ]}
                data={data?.technical_report_item_set}
                onDelete={handleRemoveTechnicalReportItem}
              />
            )}
          </div>
          <div>
            <Bar text="Instance" content={[]} />

            {loadingInstances ? (
              <div className="w-full h-full flex justify-center items-center py-4">
                <Spin />
              </div>
            ) : (
              <InstanceTable id={activeItemId} />
            )}
          </div>
        </div>
      </BaseContainer>

      <PickValueWithClassificationModal
        visible={visibleAddPropertySetDialog}
        url="left-panel-set-property-definition/"
        pickerUrl="set-property-definition/left-panel-picker/"
        onClose={() => setVisibleAddPropertySetDialog(false)}
        onSubmit={handleAddPropertySet}
      />
      <PickValueWithClassificationModal
        visible={visibleAddTechnicalReportItemSetDialog}
        url="left-panel-set-technical-report-item/"
        pickerUrl="set-technical-report-item/left-panel-picker/"
        showDetail
        onClose={() => setVisibleAddTechnicalReportItemSetDialog(false)}
        onSubmit={handleAddTechnicalReportItemSet}
      />
      <PickValueWithClassificationModal
        visible={visibleAddPropertyDialog}
        url="left-panel-property-definition/"
        pickerUrl="property-definition/left-panel-picker/"
        onClose={() => setVisibleAddPropertyDialog(false)}
        onSubmit={handleAddProperty}
      />
      <PickValueWithClassificationModal
        visible={visibleAddPTechnicalReportItemDialog}
        url="left-panel-technical-report-item/"
        pickerUrl="technical-report-item/left-panel-picker/"
        showDetail
        onClose={() => setVisibleAddPTechnicalReportItemDialog(false)}
        onSubmit={handleAddTechnicalReportItem}
      />

      <TemplateModal
        visible={templatesModalOpen}
        classificationId={data?.classification?.id}
        onClose={() => setTemplatesModalOpen(false)}
        onSubmit={handlePickTemplate}
      />
      {/* <PickValueWithClassificationModal
        visible={visibleAddComponent}
        url={`left-panel-solution/?classification=${activeComponentId}`}
        pickerUrl="solution/left-panel-picker/"
        onClose={() => setVisibleAddComponent(false)}
        onSubmit={handleAddComponent}
      /> */}
      <SolutionPickerModal
        visible={visibleAddComponent}
        onClose={() => setVisibleAddComponent(false)}
        onSubmit={handleAddComponent}
        classificationId={activeComponentId}
      />
      <SolutionPropertyDefinitionModal
        propertyId={propertyIdForSetValue}
        visible={visibleAddPropertyValueDialog}
        formProps={data?.property_set?.find(
          (property) => property.property_definition.id === propertyIdForSetValue
        )}
        onClose={() => {
          setVisibleAddPropertyValueDialog(false);
          setPropertyIdForSetValue(null);
        }}
        onSubmit={handleAddPropertyValue}
      />
      <TypeMarkModal
        title="TypeMark"
        visible={visibleBasicEditDialog}
        formProps={data}
        onSubmit={handleSubmitTypeMarkDialog}
        onClose={handleCloseTypeMarkDialog}
      />
      {visibleColorsDialog && (
        <ColorsModal
          title="Vzhledy"
          visible={visibleColorsDialog}
          formProps={data}
          onSubmit={handleSubmitColorsDialog}
          onClose={() => setVisibleColorsDialog(false)}
        />
      )}
      {geometry && showGeometryViewDialog && (
        <UpdateGeometryModal
          visible={showGeometryViewDialog}
          typeMarkId={activeItemId}
          geometry={geometry.geo}
          position={geometry.position}
          perspective={geometry.perspective}
          zoom={geometry.zoom}
          editable
          onClose={() => setShowGeometryViewDialog(false)}
          submit={() => {}}
        />
      )}
    </div>
  );
}
