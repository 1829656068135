import React from 'react';
import Modal from '../../../../../shared/modal/Modal';
import { Field, Form, Formik } from 'formik';
import Yup from '../../../../../utills/myYup';
import { useRef } from 'react';
import { InputField } from '../../../../../shared/InputField';
import Label from '../../../../Label';
import ModalButton from '../../../../../shared/modal/ModalButton';
import { AreaField } from '../../../../../shared/AreaField';

const TypeMarkModal = ({ visible, title, formProps, onSubmit, onClose, isTemplate }) => {
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('Povinné pole'),
    type_mark: !isTemplate && Yup.string().required('Povinné pole'),
  });

  const formRef = useRef(null);

  const onSubmitButton = () => {
    if (!formRef?.current) return null;
    formRef.current.submitForm();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      header={[]}
      footer={[
        <ModalButton key={1} action={() => onClose()}>
          Zavřít
        </ModalButton>,
        <ModalButton key={2} primary action={() => onSubmitButton()}>
          Potvrdit
        </ModalButton>,
      ]}
    >
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        validationSchema={ValidationSchema}
        initialValues={{
          title: formProps?.title || null,
          type_mark: formProps?.type_mark || null,
          description: formProps?.description || null,
        }}
        onSubmit={(values) => {
          const { type_mark, ...valuesWithoutTypeMark } = values;
          onSubmit(isTemplate ? { id: formProps?.id, values: valuesWithoutTypeMark } : values);
        }}
      >
        {(props) => {
          const { values } = props;
          return (
            <>
              <Form>
                <div>
                  <Label name="title" title="Název" required />
                  <Field
                    name="title"
                    value={values.title || null}
                    placeholder="Název"
                    component={InputField}
                  />
                </div>

                {!isTemplate && (
                  <div>
                    <Label name="type_mark" title="Označení" required />
                    <Field
                      name="type_mark"
                      value={values.type_mark || null}
                      placeholder="Označení"
                      component={InputField}
                    />
                  </div>
                )}

                <div>
                  <Label name="description" title="Popis" />
                  <Field
                    name="description"
                    value={values.description || null}
                    placeholder="Popis"
                    limit={600}
                    component={AreaField}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default TypeMarkModal;
