import { EditOutlined } from '@ant-design/icons';
import React from 'react';
import BaseContainer from '../../../../containers/BaseContainer';
import Bar from '../../../../elements/Bar';
import List from '../../../../elements/List';
import LogActivity from './LogActivity';

function UsersDetailView({ data, makeEditable, permission, logs, fetchMoreLogs, hasMoreLogs }) {
  if (!data) {
    return null;
  }

  const prepareDataDetail = () => {
    if (!data) return [];

    return [
      { name: 'Uživatel', value: data?.user_name },
      { name: 'Uživatelský email', value: data?.user_email },
    ];
  };

  const prepareDataSpecificRole = () => {
    if (!data) return [];

    return [
      {
        name: 'Role',
        value: data?.role?.title,
      },
      {
        name: 'Přiřazený pracovní email',
        value: data?.employee_email,
      },
      {
        name: 'Název pracovní pozice',
        value: data?.employee_job_title,
      },
      {
        name: 'Stav',
        value: data?.employee_status ? 'aktivní' : 'neaktivní',
      },
      {
        name: 'Datum nástupu pracovníka',
        value: data?.employee_employed_start,
      },
      {
        name: 'Datum odchodu pracovníka',
        value: data?.employee_employed_end,
      },
      {
        name: 'Doba ve firmě',
        value: data?.employee_employed_total_time,
      },
      {
        name: 'Datum přidání v Moiry',
        value: (data?.employee_confirmed || '').substr(0, 10),
      },
      {
        name: 'Datum posledního přihlášení',
        value: (data?.employee_last_login || '').substr(0, 10),
      },
    ];
  };

  const content = () => {
    if (permission === 2) {
      return [<EditOutlined key={1} onClick={makeEditable} />];
    }
    return [];
  };

  return (
    <div>
      <BaseContainer fullHeight={true} width={'calc(100% - 420px)'} margin>
        <Bar text={'Informace převzaté z uživatelského účtu'} content={[]} />
        <List data={prepareDataDetail()} />
        <Bar text={'Informace specifické pro roli ve firmě'} content={content()} />
        <List data={prepareDataSpecificRole()} actions={[]} />
        <Bar text={'Log aktivity pracovníka'} content={[]} />
        <LogActivity data={logs || []} fetchMoreData={fetchMoreLogs} hasMore={hasMoreLogs} />
      </BaseContainer>
    </div>
  );
}

export default UsersDetailView;
