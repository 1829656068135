import { createContext, useState } from 'react';
import Modal from '../../../../../shared/modal/Modal';
import ModalButton from '../../../../../shared/modal/ModalButton';
import View from '../../../../geometry/view';
import { updateGeometry } from '../../../../../store/actions/typemark';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

export const CameraContext = createContext();

const UpdateGeometryModal = ({
  visible,
  typeMarkId,
  onClose,
  geometry,
  editable,
  position,
  perspective,
  zoom,
}) => {
  const dispatch = useDispatch();
  const [cameraState, setCameraState] = useState();
  const [isPerspective, setIsPerspective] = useState(perspective);
  const [stage, setStage] = useState(0);

  const handleCameraChange = (camera) => {
    setCameraState(camera);
  };

  const footerButtons = () => {
    if (stage === 0)
      return [
        <ModalButton key={1} className="w-full bg-color4 p-2" action={() => setStage(1)}>
          Pokračovat
        </ModalButton>,
      ];

    return [
      <ModalButton
        key={1}
        disabled={stage === 0}
        action={() => {
          dispatch(
            updateGeometry(typeMarkId, {
              position: JSON.stringify(cameraState.matrix.toArray()),
              layers: '[]',
              perspective: isPerspective,
              zoom: cameraState.zoom,
            })
          );
          onClose();
        }}
      >
        Potvrdit
      </ModalButton>,
      <ModalButton key={2} action={() => onClose()}>
        Zavřít
      </ModalButton>,
    ];
  };

  return (
    <Modal title="Editace" visible={visible} footer={footerButtons()} header={[]} width="800px">
      {stage === 0 && (
        <div>
          <h3 className="text-sm mb-2">Typ kamery:</h3>
          <div className="flex justify-center items-center mb-2">
            <button
              className={classNames('flex-1 bg-color4 border h-20', {
                ' bg-color7 text-white': !isPerspective,
              })}
              onClick={() => setIsPerspective(false)}
            >
              Orthogonal
            </button>
            <button
              className={classNames('flex-1 bg-color4 border h-20', {
                'bg-color7 text-white': isPerspective,
              })}
              onClick={() => setIsPerspective(true)}
            >
              Perspective
            </button>
          </div>
        </div>
      )}
      {stage === 1 && (
        <View
          geometry={geometry}
          position={position}
          zoom={zoom}
          editable={editable}
          canvasId="threeModal"
          cameraType={isPerspective ? 'perspective' : 'orthogonal'}
          submit={() => {}}
          onCameraChange={handleCameraChange}
          withControlPanel
          controlPanel={<button>front</button>}
        />
      )}
    </Modal>
  );
};

export default UpdateGeometryModal;
